
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Suspense } from 'react';
import { NextSeo } from 'next-seo';
import { GetStaticPropsContext } from 'next/types';
import { Makeswift, Page as MakeswiftPage, PageProps as MakeswiftPageProps } from '@makeswift/runtime/next';
import { ReactRuntimeProvider } from '@makeswift/runtime/next';
import { renderToString } from 'react-dom/server';
import { InstantSearchServerState, getServerState } from 'react-instantsearch';
import { SWRConfig } from 'swr';
import { AppInformationDocument, AppMetaDocument, AppsRecommendedDocument, MarketplaceAppOrder } from '@/generated/contentful';
import { client as contentful } from '@/lib/contentful/client';
import { serializeCacheKey } from '@/lib/contentful/utils';
import { getPageSnapshot } from '@/lib/makeswift/client';
import '@/lib/makeswift/components';
import { runtime } from '@/lib/makeswift/runtime';
import { getSeoLocale } from '@/utils/locales/getSeoLocale';
import { pageLocales } from '@/utils/locales/page-locales';
import { AppMeta } from '@/components/Contentful/entries/Marketplace/Apps/AppMeta';
import { AppsProvider } from '@/components/Contentful/entries/Marketplace/Apps/AppsProvider';
type Props = {
    serverState?: InstantSearchServerState;
    fallback: {
        [key: string]: unknown;
    };
    locale: string | undefined;
    slug: string;
    renderAlgoliaServerState?: boolean;
    previewMode: boolean;
} & MakeswiftPageProps;
export default function Page({ snapshot, serverState, fallback, locale, slug, renderAlgoliaServerState = false, previewMode }: Props) {
    const page = renderAlgoliaServerState ? (<Suspense>
			<ReactRuntimeProvider runtime={runtime} locale={locale} previewMode={previewMode}>
				<MakeswiftPage snapshot={snapshot}/>
			</ReactRuntimeProvider>
		</Suspense>) : (<MakeswiftPage snapshot={snapshot}/>);
    const addionalMetaTagsArr = [
        {
            name: 'og:locale:alternate',
            content: 'en_US',
            keyOverride: 'og:locale:alternate_1'
        },
        {
            name: 'og:locale:alternate',
            content: 'en_AU',
            keyOverride: 'og:locale:alternate_2'
        },
        {
            name: 'og:locale:alternate',
            content: 'en_GB',
            keyOverride: 'og:locale:alternate_3'
        }
    ];
    return (<SWRConfig value={{ fallback }}>
			<NextSeo openGraph={{
            images: [
                {
                    url: 'https://www-cdn.bigcommerce.com/assets/_1200x630_crop_center-center_82_none/BigCommerce-Social-Image-Generic-Facebook.jpg?mtime=1681929501',
                    alt: 'BigCommerce logo',
                    type: 'image/jpeg'
                }
            ]
        }} additionalMetaTags={addionalMetaTagsArr.filter((loc) => loc.content !== getSeoLocale(locale))}/>
			<AppsProvider serverState={serverState}>
				<AppMeta locale={locale} slug={slug}>
					{page}
				</AppMeta>
			</AppsProvider>
		</SWRConfig>);
}
async function getStaticProps({ params, previewData, preview, locale }: GetStaticPropsContext<{
    slug: string;
}, {
    makeswift: boolean;
}>) {
    if (!pageLocales.apps?.includes(locale as Locale)) {
        console.warn('Locale not supported for apps');
        return { notFound: true, revalidate: 3600 };
    }
    const client = contentful({ preview: Boolean(preview) });
    const isMakeSwiftPreview = previewData?.makeswift == true;
    const snapshot = await getPageSnapshot({
        pathname: '/apps/__template__',
        isMakeswiftPreview: previewData?.makeswift == true,
        previewData,
        locale,
        timeout: {
            ms: 10000,
            message: `Timeout request getPageSnapshot for "/apps/__template__" – Locale: ${locale}`
        }
    });
    if (snapshot == null) {
        console.warn('Could not get snapshot for /apps/__template__');
        return { notFound: true, revalidate: 3600 };
    }
    const slug = params?.slug;
    if (slug == null)
        throw new Error('"slug" URL parameter must be defined.');
    const appInfo = await client
        .request(AppInformationDocument, {
        where: {
            slug
        }
    })
        .catch((error) => {
        console.error(error);
        return null;
    });
    if (!appInfo) {
        return { notFound: true, revalidate: 3600 };
    }
    // 404 if no app is found for a particular locale
    if (appInfo?.marketplaceAppCollection?.items &&
        appInfo?.marketplaceAppCollection?.items.length <= 0 &&
        !isMakeSwiftPreview) {
        console.warn(`No app found for slug ${slug} and locale ${locale}`);
        return { notFound: true, revalidate: 3600 };
    }
    const fallback = {
        [serializeCacheKey('appInformation', {
            slug,
            locale
        })]: appInfo,
        [serializeCacheKey('appMeta', {
            slug,
            locale
        })]: await client.request(AppMetaDocument, {
            where: { slug }
        }),
        [serializeCacheKey('appSimilarApps', {
            appId: appInfo?.marketplaceAppCollection?.items?.[0]?.sys?.id ?? null
        })]: appInfo?.marketplaceAppCollection?.items?.[0]?.similarApps
            ? await client.request(AppsRecommendedDocument, {
                where: {
                    appRegistryId_in: appInfo?.marketplaceAppCollection?.items?.[0]?.similarApps?.filter(Boolean)
                }
            })
            : null
    };
    const serverState = await getServerState(<Page snapshot={snapshot} fallback={fallback} locale={locale} slug={slug} previewMode={Makeswift.getPreviewMode(previewData)} renderAlgoliaServerState={true}/>, {
        renderToString
    });
    return {
        props: {
            snapshot,
            fallback,
            serverState,
            previewData: previewData?.makeswift == true,
            previewMode: Makeswift.getPreviewMode(previewData),
            pageId: appInfo?.marketplaceAppCollection?.items?.[0]?.sys?.id ?? null,
            locale,
            slug
        },
        revalidate: 3600
    };
}
export async function getStaticPaths() {
    if (Boolean(process.env.SKIP_BUILD_STATIC_GENERATION)) {
        return {
            paths: [],
            fallback: 'blocking'
        };
    }
    const { marketplaceAppCollection } = await contentful().request(AppInformationDocument, {
        where: { featured: true },
        order: MarketplaceAppOrder.FeaturedOrderAsc,
        limit: 5
    });
    return {
        paths: Array.from(new Set([...(marketplaceAppCollection?.items ?? []).map((entry) => entry?.slug)]))
            .filter(Boolean)
            .map((slug) => ({ params: { slug } })),
        fallback: 'blocking'
    };
}

    async function __Next_Translate__getStaticProps__196116bc3c5__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/apps/[slug]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__196116bc3c5__ as getStaticProps }
  